@import '../../kit/styles/themes.scss';

.project-thumbnail{
  display: block;
  background: $gs10;
  border-radius: $commonRadius !important;
  border: 1px solid $gs8;
  overflow: hidden;
  padding: 0rem !important;
  margin-bottom: 1.5rem;
  cursor: pointer;
  text-decoration: none;

  &:hover{
    border: 1px solid $primary;
  }

  .project-thumbnail-img-wrapper{
    overflow: hidden;
    height: 0;
    padding-top: 56.25%;
    background: $gs9;
    // border: 1px solid $gs9;
    // margin-bottom: .5rem;
    border-radius: $commonRadius $commonRadius 0 0 ;
  }

  .project-thumbnail-details{
    padding-left: .75rem;
    padding-right: .75rem;
    height: 3rem;
    .flex-split{
      height: 3rem;
    }


    .project-thumbnail-name{
      font-weight: 800;
      font-size: 1.4rem;

    }
  }
}
