@import '../../kit/styles/themes.scss';



.component-chat{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  
  // align-self: stretch;
  // overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: space-between;


  .component-chat-column{
    position: relative;
    // flex: .5;
    // height: 100%;
  }

  .component-chat-variables{
    min-width: 20rem;
    width: 20rem;
    max-width: 20rem;
    // padding: 1rem;
    // padding-right: 2rem;
    margin-right: 1rem;
    // border-right: 1px solid $gs9;
    // overflow: auto;
    // background: $gs9;
  }

  .component-chat-function{
    border-radius: $commonRadius;
    border: 1px solid $gs8;
    padding: 2rem;

    .component-chat-function-name{
      font-family: $monospaceFontFamily;
      font-size: 1.4rem;
    }

    font-size: 1.2rem;
    line-height: 1;

    &.component-chat-function-user{
      border-right: 4px solid $primary;
    }
    &.component-chat-function-bot{
      border-left: 4px solid $primary;
    }
  }

  .component-chat-inner{

    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .component-chat-form{
      background: $gs10;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      // border: 1px solid transparent;
      // background-color: $gs95;
      height: 5rem;

      textarea{
        flex-grow: 1;
        font-family: $readableFontFamily;
        font-size: 1.4rem;
        padding-top: .75rem;
        padding-left: 1rem;
        // padding-bottom: 1rem;
        border-radius: $commonRadius 0px 0px $commonRadius;
        box-sizing: border-box;
        border: 1px solid $gs6;
        border-right: none;
        resize: none;
        margin-bottom: .5rem;
        height: 5rem;
        max-height: 5rem;
        background: transparent;
        position: relative;

        &:focus,&:active{
          background: transparent;
          outline: none;
          border-color: $gs4;
          
        }
      }

      button{
        // border: none;
        border: 2px solid $gs0;
        border-radius: 0px $commonRadius $commonRadius 0px;
        background: $gs0;
        color: $gs10;
        font-size: 1.4rem;
        width: 5rem;
        height: 5rem;
        min-width: 5rem;
        min-height: 5rem;
        cursor: pointer;
        
        &:hover{
          color: $primary;
        }
        &:active{
          color: $primary;
        }

        &.component-chat-send-disabled{
          // background: $gs8;
          // border: 2px solid $gs8;
          color: $gs10;
          cursor: not-allowed;
          &:hover{
            color: $gs10;
          }
          &:active{
            color: $gs10;
          }
        }
      }
    }


    .component-chat-convo{
      flex-grow: 1;
      
      overflow-y: scroll;
      overflow-x: hidden;
      // padding: 10px;

      .component-chat-row-wrapper{
        &:last-child{
          margin-bottom: 10px;
        }
      }
     
      .component-chat-row{
        display: flex;
        align-items: center;

        &:last-child{
          margin-bottom: 10px;
        }
        // margin-bottom: 5px;;
        // padding-left: 10px;
        // padding-right: 10px;

        &.component-chat-row-topic-change{
          font-size: 1.2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 3rem;
          margin-bottom: 2rem;

          .component-chat-row-topic-change-hr{
            border-bottom: 1px solid $gs9;
            flex: .5;
          }
          .component-chat-row-topic-change-details{
            max-width: 50%;
            text-align: center;
            color: $gs4;
            margin-left: 1rem;
            margin-right: 1rem;
          }
        }

        &.component-chat-row-bot{
          // justify-content: flex-start;
          // padding-right: 30%;
          .component-chat-message-time{
            text-align: left;
          }
        }

        &.component-chat-row-user{
          // justify-content: flex-end;
          // padding-left: 30%;
          // padding-right: 0.5rem;
          .component-chat-message-time{
            text-align: left;
          }
        }

        .component-chat-message-delete-button{
          position: relative;
          margin: 10px;
          top: 6px;
          cursor: pointer;
          color: $gs7;
          &:hover{
            color: $danger;
          }
        }

        .component-chat-message-time{
          font-size: 10px;

          &.component-chat-message-time-faded{
            opacity: .5;
          }
        }



        .component-chat-message{
          position: relative;

          // z-index: 200;
          &.handle{
            cursor: grab;
            &:active{
              cursor: grabbing;
            }
          }

          .component-chat-message-body{
            padding: 14px;
            padding-top: 0px;
            padding-bottom: 8px;
            padding-left: 34px;
            font-size: 14px;
            margin: 1px;
            font-family: $readableFontFamily;
            > span{
                
              > p{
                &:first-child{
                  margin-top: 0px;
                }
                &:last-child{
                  margin-bottom: 0px;
                }
              }
            }

  
            > p{
              &:first-child{
                margin-top: 0px;
              }
              &:last-child{
                margin-bottom: 0px;
              }
            }

            ul, ol{
              margin: 0;
              font-size: 1.5rem;
            }

            &.component-chat-message-body-faded{
              opacity: .33;
            }
          }  

          // &.component-chat-message-bot{
          //   .component-chat-message-body{
          //     background: $gs10;
          //     color: $gs0;
          //     border-radius: 20px 20px 20px 0px;
          //     border: 1px solid $gs8;
          //     &.component-chat-message-body-double-send{
          //       border-radius: 0px 20px 20px 0px;
          //     }
          //   }
          // }
          // &.component-chat-message-user{
          //   .component-chat-message-body{
          //     background: $gs0;
          //     color: $gs10;
          //     border-radius: 20px 20px 0px 20px;
          //     &.component-chat-message-body-double-send{
          //       border-radius: 20px 0px 0px 20px;
          //     }
          //   }
          // }
        }
      }
    }

  }
}
