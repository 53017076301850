@import 'kit/styles/themes.scss';

.force-graph{
  .force-graph-node{
    cursor: pointer;
    stroke-width: 1px;
    stroke: $gs4;
    fill: $gs10;
    box-shadow: $boxShadowSmall;

    &:hover{
      stroke: $gs8;
      stroke-width: 2px;
    }

    &.force-graph-node--selected{
      stroke: $gs0;
      stroke-width: 2px;
    }

    &.force-graph-node--root{
      stroke: darken($inquiry, 10%);
      stroke-width: 2px;
      fill: $inquiry;
      &.force-graph-node--selected{
        stroke: $gs0;
        fill: $inquiry;
      }
    }

    &.force-graph-node--highest{
      fill: $success;
      stroke: darken($success, 30%);
      &.force-graph-node--selected{
        stroke: $gs0;
        fill: $success;
      }
    }

    &.force-graph-node--high{
      fill: lighten($success, 25%);
      stroke: $success;
      &.force-graph-node--selected{
        stroke: $gs0;
        fill: lighten($success, 25%);
      }
    }

    &.force-graph-node--normal{
      fill: $gs8;
      stroke: $gs4;
      &.force-graph-node--selected{
        stroke: $gs0;
        fill: $gs7;
      }
    }


    &.force-graph-node--low{
      fill: lighten($warning,30%);
      stroke: darken($warning, 10%);
      &.force-graph-node--selected{
        stroke: $gs0;
        fill: $warning;
      }
    }

    &.force-graph-node--lowest{
      fill: lighten($danger,30%);
      stroke: darken($danger, 10%);
      &.force-graph-node--selected{
        stroke: $gs0;
        fill: $danger;
      }
    }

  }

  .force-graph-node-label{
    // font-size: 10px;
    font-weight: 400;
    fill: $gs0;
    opacity: .75;
    pointer-events: none;

    &.force-graph-node-label--selected{
      // font-size: 12px;
      opacity: 1;
      font-weight: 900;
    }
  }

  .link{
    stroke: $gs8;
    stroke-width: 1px;
  }


  .vpc-compass-circle{
    fill: $gs10;
    stroke: $gs5;
  }

  .vpc-node-arrow{
    stroke: $gs0;
    opacity: .5;;
  }


  .force-graph-background{
    stroke: $danger;
    stroke-width: 30px;
  }
}

