@import 'kit/styles/themes.scss';

.visual-programming-canvas{
  .vpc-node{
    cursor: pointer;
    

    foreignObject{
      pointer-events: none;
    }
  }


  .vpc-node-handle{
    cursor: pointer;
    stroke: $gs0;
    fill: $gs10;
    transition: all .2s ease-in-out;
    &:hover{
      fill: $gs0;
      r: 10;
    }
  }

  .vpc-node-label{
    padding: 1.5rem;
    // padding-top: .6rem;
    fill: $gs0;
    font-size: 1.2rem;
    font-weight: 900;

    .vpc-node-label-text{
      text-transform: uppercase;
      
      color: $gs0;
      opacity: .5;
      font-size: 1rem;
      
      word-wrap: break-word;
    }

    .vpc-node-label-name{
      // font-size: 1rem;
      font-weight: 600;
      font-size: 1.4rem;
    }

    .vpc-node-label-icon{
      margin-right: .8rem;
      font-size: 1.4rem;
    }
  }
  

  .vpc-link{
    stroke: $gs0;
    pointer-events: none;
    stroke-width: 2px;
    transition: stroke-width .2s ease-in-out;
    
    &.vpc-link--selected{
      stroke: $gs0;
      stroke-width: 5;
    }
  }

  #vpc-link-in-progress{
    stroke: $gs0;
    stroke-width: 2;
    stroke-dasharray: 5,5;
    // opacity: .75;  
    pointer-events: none;
    fill: none;
  }

  .vpc-link-click-target{
    opacity: 0;
    stroke: $gs0;
    cursor: pointer;
  }

  .vpc-compass-circle{
    fill: $gs10;
    stroke: $gs5;
  }

  .vpc-node-arrow{
    stroke: $gs0;
    opacity: .5;;
  }

  .vpc-background{
    stroke: $danger;
    stroke-width: 30px;
  }


  .vpc-node-group{
    rect{
      transition: stroke-width .2s ease-in-out;
    }
    &.vpc-node-group--selected{
      rect{
        stroke-width: 8px;
      }
    }
  }
}