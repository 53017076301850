@import '../../kit/styles/themes.scss';

.footer{
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  padding: 2rem;
  margin-top: 2rem;

  .footer-zebra{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 300px;
    height: 300px;;
    z-index: 0;
  }

  .row{
    z-index: 2;
  }

  &.footer-padless{
    padding-bottom: .5rem;
    padding-right: .5rem;
  }

  .footer-big{
    // margin-bottom: 40rem;
  }

  &.footer-inverted{
    background: $gs0;
    color: $gs10;
  }

  &.footer-simple{
    // border-top: 1px solid $gs9;
  }

  .footer-left{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > * {
      margin-right: 1.5rem;
    }
  }

  .footer-right{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > * {
      margin-left: 1.5rem;
    }
  }

  a{
    text-decoration: none;
    font-weight: 400;
  }
}
