@import '../../kit/styles/themes.scss';

.component-version-bar{
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding-left: 1rem;
  // padding-right: 1rem;
  width: 100%;
  .component-version-bar-viz{
    position: relative;
    flex-grow: 1;
    height: 4rem;
    .component-version-bar-viz-svg{
      position: absolute;
      height: 4rem;
      width: 100%;

      .component-version-bar-dot{
        fill: $success;
        stroke: $gs10;
        stroke-width: 1;
        cursor: pointer;
        &:hover{
          stroke: $success;
        }
      }
      .component-version-bar-line{
        stroke-width: 4;
        stroke-linecap: round;
        stroke: $gs9;
        &.component-version-bar-line-success{
          stroke: $success;
        }
      }

      .component-version-bar-text-label{
        font-size: 1.1rem;
        fill: $gs5;
      }
    }
  }
}
  