@import 'kit/styles/themes.scss';

.knowledge-home{
  // padding: 2rem;

  .knowledge-home-feed-item{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    padding-bottom: 3rem;

    .knowledge-home-feed-icon{
      background: $gs9;
      border: 1px solid $gs9;
      border-radius: 100px;
      min-width: 1.6rem;
      min-height: 1.6rem;
      max-width: 1.6rem;
      max-height: 1.6rem;
      // overflow: hidden;
      font-size: 1rem;
      color: $gs5;
      margin-top: .5rem;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      z-index: 2;

      &:before{
        z-index: 1;
        content: "";
        position: absolute;
        top: 2.2rem;
        background: $gs9;
        width: .1rem;
        height: 10rem;
      }
    }

    &.knowledge-home-feed-last-item{
      .knowledge-home-feed-icon{
        &:before{
          display: none;
        }
      }
    }

    .knowledge-home-feed-avatar-slot{
      margin-right: 1rem;
    }

    .knowledge-home-feed-content{
      padding-top: .5rem;
      flex-grow: 1;


      .knowledge-home-feed-header{
        margin-bottom: .5rem;
      }

      .knowledge-home-feed-event-descriptor{
        font-size: 1.4rem;
        color: $gs4;
        a{
          color: $gs0;
          &:hover{
            color: $primary;
          }
        }
      }
    }

  }
}
