@import '../../kit/styles/themes.scss';

$animationSpeed: 200ms;

.header{
  z-index: 200;
  // margin-bottom: 2rem;
  &.header-logged-in{
    height: 6rem;
    min-height: 6rem;

  }
  &.header-logged-out{
    height: 6rem;
    min-height: 6rem;
    // margin-bottom: 1rem;
  }

  &.header-white-background{
    .header-inner{
      background: $gs10;;
    }
  }

  &.header-white-drop-shadow{
    .header-inner{
      box-shadow: 0px 5px 5px rgba(255,255,255,1);
    }
  }

  &.header-black-background{
    .header-inner{
      background: $gs0;
      // border-bottom: 1px solid $gs2;
      color: $gs10;
      a{
        color: $gs10;
      }
    }
  }
  
  .header-inner{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 0rem 1rem;
    // background: $gs10;
    border-bottom: 1px solid $gs0;
    z-index: 800;
    font-size: 1.5rem;
    color: $gs0;
    left: 0rem;


    transition: left $animationSpeed ease-in-out;

    &.header-inner-logged-out{
      height: 6rem;
      // padding-left: 2rem;
      left: 0rem;
      &.header-inner-logged-out-loading{

        &:after{
          content: '';
          position: absolute;
          left: 0rem;
          right: 0rem;
          top: 6rem;
          height: 1px;
          background: linear-gradient(90deg, transparent, transparent 48.9%, $primary 49%, $primary 51.9%, transparent 52%, transparent);
          background-size: 400% 400%;
          animation: gradient 2s ease-in-out infinite alternate;
        }

        @keyframes gradient {
          0% {
            background-position: 200% 50%;
          }
          100% {
            background-position: 165% 50%;
          }
        }
      }
    }

    &.header-inner-logged-in{
      height: 6rem;
      position: relative;
      padding-left: 1rem;
      padding-right: .5rem;

      // color: $gs0;
      font-weight: 900;

      position: fixed;
      top: 0rem;
      left: 0rem;
      right: 0rem;

      &.header-inner-logged-in-loading{

        &:after{
          content: '';
          position: absolute;
          left: 0rem;
          right: 0rem;
          top: 6rem;
          height: 1px;
          background: linear-gradient(90deg, transparent, transparent 48.9%, $primary 49%, $primary 51.9%, transparent 52%, transparent);
          background-size: 400% 400%;
          animation: gradient 2s ease-in-out infinite alternate;
        }

        @keyframes gradient {
          0% {
            background-position: 200% 50%;
          }
          100% {
            background-position: 165% 50%;
          }
        }
      }



      .header-menu-button{
        cursor: pointer;
        height: 100%;
        width: 6rem;
        min-width: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        line-height: 6rem;
        background: $gs9;
        color: $gs4;
        &:hover{
          background: $gs9;
          color: $gs0;
        }

        &.header-menu-button-collapse-mode{
          background: $gs0;
          &:hover{
            background: $gs0;
            color: $primary;
          }
        }
      }
    }

    .header-brand-text{
      font-size: 2.6rem;
      margin-left: .25rem;
      position: relative;
      font-family: $brandFontFamily;
    }
    .header-brand-logo{
      margin-right: 1rem !important;
      width: 33px;
      height: 33px;
    }


    a{
      text-decoration: none;
      color: $gs0;
      
      &:hover{
        color: $gs5;
      }
      // font-weight: 900;
    }

    h1{
      font-size: 2rem;
    }

    .custom-field{
      input{
        border: none;
        border-radius: 0px;
        // border-left: 1px solid $gs8;
        border-right: 1px solid $gs8;
        height: 6rem;
        background: none;
        &:hover{
          // background: $gs9;
        }
      }
    }

    .header-current-major-dropdown{
      background: $gs0;
      font-size: 1.75rem;
      font-weight: 400;
      // padding: .65rem 1rem;
      padding-left: 1rem;
      // border-radius: $commonRadius;
      height: 6rem;
      display: flex;
      align-items: center;
      &:hover{
        // background: $gs9;
        color: $primary;
      }
    }

    .header-left{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > * {
        margin-right: 1.5rem;
      }
    }

    .header-right{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > * {
        margin-left: 1.5rem;
      }
    }

    .avatar-online{
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 1px;
        right: -2px;
        width: .8rem;
        height: .8rem;
        background: $success;
        border-radius: 1000rem;
        border: 1px solid $gs10;
      }
    }

    .avatar-offline{
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 1px;
        right: -2px;
        width: .8rem;
        height: .8rem;
        background: $gs7;
        border-radius: 1000rem;
        border: 1px solid $gs10;
      }
    }
  }


  &.header-landing{
    .header-inner{
      // padding-right: 2rem;
    }
  }

  &.header-borderless{
    .header-inner{
      border-bottom: 1px solid transparent;
    }
  }

  .header-obj-avatar{
    // height: 30px;
    margin-top: -3px;
  }

  .header-obj-details{
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    height: 6rem;

    .header-obj-details-parent{
      position: absolute;
      top: 0;
      height: 6rem;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .header-obj-details-child{
      
      display:inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x:hidden;
    }

    .header-obj-name{
      font-weight: 900;
      line-height: .7;
      
      &.header-obj-name-w-description{
        margin-top: .7rem;  
      }
      // margin-bottom: -.2rem;
    }
    .header-obj-description{
      margin-top: .3rem;
      font-size: 1.2rem;
      font-weight: 400;
      color: $gs0;
      opacity: .75;
    }
  }


  &.header-obj-mode{
    .header-inner{
      .header-brand{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 6rem;
        // background: $danger;
        padding-right: 2rem;
        border-right: 1px solid $gs8;
        h1{
          margin-bottom: .5rem !important;
        }
      }
    }
  }

  &.header-side-tray-open{
    .header-inner{
      left: 27rem;
    }
  }
  &.header-side-tray-docked{
    .header-inner{
      left: 4rem;
    }
  }
  
  .header-link{
    font-size: 1.5rem;
  }
}
