@import 'kit/styles/themes.scss';

.inquiry-context-map{
  position: relative;

  .inquiry-context-map-header{
    z-index: 20;
  }

  .inquiry-context-map-graph{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  .inquiry-context-map-node-info{
    max-width: 25%;
    min-width: 350px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    z-index: 20;

  }
}

