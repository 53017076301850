@import '../../kit/styles/themes.scss';

.hydrate{
  
  .hydrate-lockup-wrapper{
    display: inline-block;

    .hydrate-lockup-inner{
      display: flex;
      align-items: center;

      .hydrate-lockup-avatar{
        padding-right: 1rem;
      }
    }
  }


  .hydrate-detail{
    overflow: hidden;
  }
  // scaling rectangle in 16:9 aspect ratio
  .hydrate-component-flow-wrapper{
    display: inline-block;
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;
    background: $gs95;
    border-radius: $commonRadius;
    // margin-bottom: .5rem;
    // margin-top: 1rem;
    // &:after {
    //   content: "";
    //   display: block;
    //   padding-bottom: 56.25%;
    // } 

    .hydrate-component-flow-inner{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &.hydrate-component-flow-inner--empty{
        display: flex;
        justify-content: center;
        align-items: center;
        color: $gs5;
        // font-weight: 900;
      }
    }
  }

}



.hydrate-avatar-circle-wrapper{
  display: inline-block;
  border-radius: 100000rem;
  overflow: hidden;
  background: $gs8;
  width: 100%;

  // &:after {
  //   content: "";
  //   display: block;
  //   padding-bottom: 100%;
  // } 
}


.hydrate-avatar-rounded-square-wrapper{
  display: inline-block;
  border-radius: 10%;
  overflow: hidden;
  background: $gs8;
  width: 100%;

  // &:after {
  //   content: "";
  //   display: block;
  //   padding-bottom: 100%;
  // } 
}