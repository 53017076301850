@import 'kit/styles/themes.scss';

.inquiry-opportunity-map{
  position: relative;

  .inquiry-opportunity-map-header{
    z-index: 20;
  }

  .inquiry-opportunity-map-graph{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  .inquiry-opportunity-map-tools{
    // position: absolute;
    // top: 0;
    // right: 0;
    width: 3.5rem;
    z-index: 20;

    hr{
      margin: 0;
      border: 0;
      border-top: 1px solid $gs9;
    }
    .inquiry-opportunity-map-tool{
      display: block;
      width: 100%;
      height: 3.5rem;
      background: $gs10;
      color: $gs4;
      text-align: center;
      line-height: 3.5rem;
      cursor: pointer;
      &:hover{
        // background: $gs9;
        color: $inquiry;
      }
      &.inquiry-opportunity-map-tool-selected{
        background: $gs10;
        color: $gs0;
      }
    }
  }

  .inquiry-opportunity-map-node-info{
    max-width: 25%;
    min-width: 350px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    z-index: 20;

  }
}

