@import 'kit/styles/themes.scss';

.component-flow{
  position: relative;

  .component-flow-header{
    z-index: 20;
  }

  .component-flow-graph{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  .component-flow-tools{
    // position: absolute;
    // top: 0;
    // right: 0;
    width: 3.5rem;
    z-index: 20;

    hr{
      margin: 0;
      border: 0;
      border-top: 1px solid $gs9;
    }

    .component-flow-tool{
      display: block;
      width: 100%;
      height: 3.5rem;
      background: $gs10;
      color: $gs4;
      text-align: center;
      line-height: 3.5rem;
      cursor: pointer;
      &:hover{
        // background: $gs9;
        color: $primary;
      }

      &:first-child{
        border-top-left-radius: $commonRadius;
        border-top-right-radius: $commonRadius;
      }
      &:last-child{
        border-bottom-left-radius: $commonRadius;
        border-bottom-right-radius: $commonRadius;
      }

      &.component-flow-tool-selected{
        background: $gs10;
        color: $gs0;
      }
      &.component-flow-tool-wide{
        width: 335px;
        text-align: left;
        display: flex;
        background: none;;
        &:hover{
          color: $gs4;
        }

        .component-flow-tool-icon{
          padding-left: .8rem;
          padding-right: .8rem;
          background: $gs10;
          &:hover{
            color: $danger; 
          }
        }
        .component-flow-tool-wide-content{
          flex-grow: 1;

        }
      }
    }

  }

  .component-flow-tool-search-bar{
    margin-left: .5rem;

    .custom-select{
      color: $gs0;
           
      .react-select-container{

        .react-select__menu{
          > * {
            > * {
              padding: 0;
            }
          }
        }
        
        .react-select__control{
          border: 1px solid $gs9;
          // border-left: none;
          background: $gs10;
          z-index: 20;
          
          min-height: 3.5rem;
          height: 3.5rem;
          max-height: 3.5rem;
          opacity: 1;
          &.react-select__control--is-focused{
            opacity: 1;
          }
          .react-select__value-container{
            min-height: 3.5rem;
            height: 3.5rem;
            max-height: 3.5rem;
            padding: 0px 8px;
            font-size: 1.3rem;
            
            .react-select__input-container{
              height: 3.5rem;
              max-height: 3.5rem;
              padding-top: 0px;
              padding-bottom: 0px;
              margin: 0px;
            }
          }
        }
      }
    }
  }

  .component-flow-node-info{
    max-width: 33%;
    min-width: 450px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    z-index: 20;

  }
}

