$brandFontFamily: 'DM Serif Display', serif;
$monospaceFontFamily: 'Roboto Mono', monospace;
$serifFontFamily: 'PT Serif', serif;;
$readableFontFamily: "Anuphan",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

$primary: #1FB8FF;
$secondary: #FF4759;

$primaryDarker: darken($primary, 15%);

$success: #53b882;
$danger: #D24128;
$warning: #faaa35;
$info: #71CDFF;
$yellow: #FFDC5C;
$pink: #ea596e;
$purple: #7851a9;
$green: #53b882;
$blue: #027CFE;
$orange: #faaa35;
$dark-soft: #8899A6;
$dark: #424E57;

$component: #1FB8FF;
$inquiry: #F94E76;
$knowledge: #00976D;
$project: #FE9B06;

$white: #fff;
$lightest-grey: #F8F9FB;
$light-grey: #E1E4EA;
$medium-grey: #C0C7D3;
$grey: #788690;
$dark-grey: #1B262E;
$darkest-grey: #141B1F;
$black: #0A0E0F;


$g: darken(desaturate($lightest-grey, 6%), 100%); 
$gs10: lighten($g, 100%);
$gs95: lighten($g, 95%);
$gs9: lighten($g, 90%);
$gs8: lighten($g, 80%);
$gs7: lighten($g, 70%);
$gs6: lighten($g, 60%);
$gs5: lighten($g, 50%);
$gs4: lighten($g, 40%);
$gs3: lighten($g, 30%);
$gs2: lighten($g, 20%);
$gs1: lighten($g, 10%);
$gs0: $g;

$link-color: $primary;
$link-hover: darken($primary, 15%);
$link-active: darken($primary, 30%);


$commonRadius: 8px;
$fieldBorderRadius: 4px;
$fieldBorderThickness: 1px;

$boxShadowSmall: 0 1px 4px 0 rgba(8, 11, 14, 0.06);// 0 3px 3px -1px rgba(8, 11, 14, 0.1);
$boxShadowMedium: 0 0 1px 0 rgba(8, 11, 14, 0.06), 0 6px 6px -1px rgba(8, 11, 14, 0.1);
$boxShadowLarge: 0 0 1px 0 rgba(8, 11, 14, 0.06), 0 12px 12px -1px rgba(8, 11, 14, 0.08);

$boxShadowSmallInverted: 0 -1px 4px 0 rgba(8, 11, 14, 0.06);
$boxShadowMediumInverted: 0 -1px 4px 0 rgba(8, 11, 14, 0.06), 0 -6px 6px -1px rgba(8, 11, 14, 0.1);
$boxShadowLargeInverted: 0 -1px 4px 0 rgba(8, 11, 14, 0.06), 0 -12px 12px -1px rgba(8, 11, 14, 0.08);


$boxShadowSmallHover: 0 0 1px 0 rgba(8, 11, 14, 0.06), 0 12px 12px -1px rgba(8, 11, 14, 0.1);

$boxShadowBorder: 1px solid #F7F8F9;

:export {
  success: $success;
  danger: $danger;
}

::-webkit-scrollbar-button { 
  display: none; 
  height: 13px;
  background-color: $gs1;
} 

::-webkit-scrollbar-button:hover { 
  background-color: transparentize($gs1, .5); 
} 

::-webkit-scrollbar-thumb { 
  background-color: $gs1;
  box-shadow: none;
  cursor: grab;
  border-radius: 4px;
} 

::-webkit-scrollbar-thumb:hover { 
  background-color: transparentize($gs1, 0); 
  cursor: grab !important;
} 

::-webkit-scrollbar-track { 
  border-radius: 0;
  background-color: transparent !important;
} 

::-webkit-scrollbar-track:hover { 
  background-color: transparent !important;
}

::-webkit-scrollbar { 
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: transparent !important;
}

::-webkit-scrollbar-corner { 
  opacity: 0;
}
