@import '../../kit/styles/themes.scss';

.workbench{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $gs0;
  // overflow: hidden;

  .workbench-header-bumper{
    height: 6rem;
  }

  .workbench-body{
    flex-grow: 1;
    border-radius: 4px 0 0 0;
    overflow: hidden;
    background: $gs10;


    >.scroll-child{
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
    

    .workbench-body-inner{
      flex-grow: 1;
    }

    .workbench-body-padding{
      padding: 1.5rem;
    }
  }

  .workbench-header{
    // height: 12rem;
    // padding: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 6rem;
    background: $gs0;
    color: $gs10;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 200;

    .workbench-search-header-icon{
      z-index: 2;
      opacity: .5;
      margin-right: 3rem;
      &:hover{
        opacity: 1;
      }
    }

    .workbench-search-form{
      margin-right: .2rem;
      .workbench-search-form-input{
        overflow: hidden;
        width: 0px;
        font-size: 1.4rem;
        transition: width 400ms ease-in-out;

        &.workbench-search-form-input-open{
          width: 300px;
          position: relative;
          right: -25px;
        }
        .custom-field{
          input{
            background: none;
            font-size: 1.3rem;
            border: 1px solid $gs3;
          }
        }
      }
    }


    .avatar-online{
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 1px;
        right: -2px;
        width: .8rem;
        height: .8rem;
        background: $success;
        border-radius: 1000rem;
        border: 1px solid $gs10;
      }
    }

    .avatar-offline{
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 1px;
        right: -2px;
        width: .8rem;
        height: .8rem;
        background: $gs7;
        border-radius: 1000rem;
        border: 1px solid $gs10;
      }
    }

    .header-brand-text{
      font-size: 2.3rem;
      margin-left: 0;
      position: relative;
      margin-right: 2rem;
      // top: -2px;
    }
    .header-brand-logo{
      margin-right: 1rem !important;
      width: 33px;
      height: 33px;
    }

    .custom-select{
      .react-select__control{
        background: $gs2;
        color: $gs10 !important;
        border: none;//1px solid $gs3;
        height: 3.5rem;
        min-height: 3.5rem;
        font-size: 1.4rem;

        &:hover{
          background: $gs3;
          cursor: pointer;
        }

        .react-select__single-value{
          color: $gs10;
          // font-weight: 900;
        }
      }

      .react-select__dropdown-indicator{
        display: none;
      }
      .react-select__indicator-separator{
        display: none;
      }
      .react-select__value-container{
        // padding-right: 0;
      }
    }

    .custom-field{
      input{
        background: $gs3;
        border: 2px solid $gs3;
        color: $gs10;
      }
    }

    h1{
      font-size: 2.8rem;
      margin-top: 0rem;
      margin-bottom: 1.5rem;

    }

    .custom-select{
      color: $gs0;
    }

    .workbench-header-top{
      height: 6rem;
      border-bottom: 1px solid $gs2;
      padding: 1rem;
      box-sizing: border-box;

      .workbench-header-top-left{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        > * {
          margin-right: 1.5rem;
        }
        a{
          text-decoration: none;
        }
      }

      .workbench-header-top-right{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        > * {
          margin-left: 1.5rem;
        }
        a{
          text-decoration: none;
        }


        .avatar-online{
          position: relative;
          &:after{
            content: '';
            position: absolute;
            bottom: 1px;
            right: -2px;
            width: .8rem;
            height: .8rem;
            background: $success;
            border-radius: 1000rem;
            border: 1px solid $gs10;
          }
        }

        .avatar-offline{
          position: relative;
          &:after{
            content: '';
            position: absolute;
            bottom: 1px;
            right: -2px;
            width: .8rem;
            height: .8rem;
            background: $gs7;
            border-radius: 1000rem;
            border: 1px solid $gs10;
          }
        }
      }
    }
  }


  .workbench-menu-body-wrapper{
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    
    .workbench-menu-wrapper{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 20rem;

      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;

      overflow-y: auto;
    }

    .workbench-menu{
      position: relative;
     
      background: $gs0;
      transition: width 400ms ease-in-out;
   

      &.workbench-menu-open{
        width: 20rem;

        .workbench-menu-link-subitems{
          max-height: 200px;
        }

        .workbench-menu-link{
          &:first-child{
            margin-top: 0rem;
          }
          border-radius: $commonRadius;
          margin: 1rem;
          


          .workbench-menu-link-icon{
            font-size: 1.5rem;
            width: 4rem;
            min-width: 4rem;
          }
        }
      }

      &.workbench-menu-closed{
        width: 5rem;
        
        .workbench-menu-link-subitems{
          max-height: 0px;
          margin: 0px;
          margin-bottom: 0px;
        }

        .workbench-menu-link{
          height: 5rem;
          &:first-child{
            margin-top: 0rem;
          }
          border-radius: 0;
          margin: 0rem;


          .workbench-menu-link-icon{
            font-size: 1.8rem;
            width: 5rem;
            min-width: 5rem;
          }
        }
      }
      

      .workbench-menu-link{
        transition: all 400ms ease-in-out, background-color 10ms ease-in-out;
        height: 3.5rem;
        color: $gs10;
        cursor: pointer;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-decoration: none;
        color: $gs5;

        &.workbench-menu-link-active{
          background-color: $gs3;
          color: $gs10;
          .workbench-menu-link-icon{
            font-weight: 900;
          }
          
          .workbench-menu-link-text{
            color: $gs10;
          }
        }

        &:hover{
          background-color: $gs2;
        }
        &:active{
          background-color: $gs4;
        }

        .workbench-menu-link-icon{
          font-size: 1.5rem;
          width: 5rem;
          min-width: 5rem;
        }

        .workbench-menu-link-text{
          flex-grow: 1;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 900;
          font-size: 1.3rem;
          color: $gs9;
        }

      }
      
      .workbench-menu-link-new-icon{
        color: $gs5;
        height: 3.5rem;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding-left: 1rem;

        &:hover{
          background-color: $gs3;
        }

        &:active{
          background-color: $gs1;
        }
      }

      .workbench-menu-link-subitems{
        overflow: hidden;
        margin: 1rem;
        margin-top: -.75rem;
        padding-left: 4rem;
        margin-bottom: 2rem;
        transition: max-height 400ms ease-in-out, margin 400ms ease-in-out, padding 400ms ease-in-out;
        max-height: 200px;
        
        .workbench-menu-link-subitem{
          color: $gs5;
          font-weight: 400 !important;  
          text-decoration: none;
          font-size: 1.3rem;
          display: block;
          margin-bottom: 0.5rem;
          &:hover{
            color: $gs8;
          }
          &:active{
            color: $gs10;
          }

          &.workbench-menu-link-subitem-active{
            color: $gs10;
            font-weight: 900 !important;
          }
        }
      }
    }
  }

  .workbench-component{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .workbench-kind-right-half{
    // max-width: calc(100% - 245px);
    background: $gs95;
    > .scroll-child{
      // padding-left: 2rem !important;
    }
  }

  .workbench-grey-bg{
    background: $gs95;
  }

  .workbench-kind-left-menu{
    position: relative;
    box-shadow: 1px 0px 2px 0px rgba(0,0,0,0.04);
    border-right: 1px solid $gs95;
    z-index: 2;

    .workbench-kind-left-menu-inner{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;

      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
    
    }
  }
  
  .workbench-kind-collapse-button{
    min-height: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $gs5;
    font-size: 1.6rem;
    &:hover{
      background: $gs9;
    }
  }

  .workbench-kind-category-label{
    border-top: 1px solid $gs9;
    padding: 1rem;
    font-size: 1.4rem;
    color: $gs7;
    font-weight: 900;
  }

  .workbench-nested-bookmark-nav{
    position: absolute;
    left: 1rem;
    right: 1rem;
    top: 1rem;
    z-index: 100;

    &.no-pointer-events{
      pointer-events: none;
      z-index: 1;
    }

    .workbench-nested-bookmark-nav-footer{
      // position: absolute;
      // bottom: 0;
      // left: 0;
      // right: 0;
      position: relative;
      top: 1px;
    }
  }

  .workbench-nested-bookmark-nav-bumper{
    height: 11rem;
  }
  
}

.workbench-tip-zebra{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 250px;
}


.overlapping-avatar-list{
  position: absolute;
  right: 30px;
  > * {
    display: inline-block;
    margin-right: -7px;
  }

  .overlapping-avatar-list-more{
    position: absolute;
    right: 25px;
  }
}