@import 'kit/styles/themes.scss';

.inquiry-foundations{
  .stack-overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;

    .stack-dashed-line{
      stroke: $gs9;
      stroke-width: 1;
      stroke-dasharray: 5,5;
    }

    .stack-section-label{
      fill: $gs7;
      font-size: 1.2rem;
    }
    .stack-template-rect{
      fill: $gs95;
      opacity: .3;
    }
  }

  .stack-text-overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 0.5rem;
    font-size: 1.2rem;

  }

  canvas{
    z-index: 2;
    position: relative;
  }
}

