@import 'kit/styles/themes.scss';

.component-review{

  .tool-content{
    padding: 2rem;
  }


  .component-review-counter{
    font-size: 1.2rem;
  }

  .component-review-bar{
    position: relative;
    margin-top: 3.5rem;
    margin-bottom: 1rem;
    border-radius: 2rem;
    overflow: hidden;
    height: .5rem;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .component-review-bar-section{
      display: inline-block;
      // height: 1rem;
      // position: relative;
      // top: 1rem;

      &.component-review-bar-section-none{
        background: $gs8;
      }

      &.component-review-bar-section-add{
        background: $success;
      }

      &.component-review-bar-section-edit{
        background: $warning;
      }

      &.component-review-bar-section-remove{
        background: $danger;
      }
    }
  }


  .component-review-table{
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;

    thead{
      tr{
        th{
          // border-bottom: 2px solid $gs0;
          font-size: 2rem;
          font-weight: 900;
          padding: 1rem;

        }
      }
    }

    td{
      vertical-align: top;
    }

    .component-review-category-row{
      td{
        color: $primary;
        font-weight: 900;
        padding: 1rem;
        border-bottom: 1px solid $gs9;
        &.component-review-category-row-spacer{
          border: none;
        }
      }
    }

    .component-review-arrow-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 5.7rem;
    }

    .component-review-item{
      padding-left: 1rem;
      padding-right: 1rem;
      max-width: 50%;
    }

    .component-review-item-key{
      font-size: 1.3rem;
      font-weight: 900;
      margin-top: 2rem;
      
    }

    .component-review-item-value{
      margin-bottom: .5rem;
      margin-top: .5rem;
      // background: $gs9;
      // border: 1px solid transparent;
      color: $gs0;
      // padding: 1.5rem;
      font-size: 1.4rem;
      border-radius: $commonRadius;
      // opacity: .6;
        // border: 1px solid $gs9;

      &.component-review-item-value-null{
        background: none;;
      }

      &.component-review-item-value-change-add{
        opacity: 1;
        color: $gs0;
        background: $gs10;
        // box-shadow: 0px 2px 8px rgba(0,0,0,.15);
      }

      &.component-review-item-value-change-remove{
        opacity: 1;
        background: $gs10;
        // box-shadow: 0px 2px 8px rgba(0,0,0,.15);
      }

      &.component-review-item-value-change-edit{
        opacity: 1;
        background: $gs10;
        // box-shadow: 0px 2px 8px rgba(0,0,0,.15);
      }
    }

  }
}
