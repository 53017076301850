@import '../../styles/themes.scss';

.simple-chart{
  position: relative;
  overflow: hidden;
  svg{
    height: 100px;
    width: 100%;
    // background: $gs95;
  }
  width: 100%;

  .simple-chart-fill{
    fill: $primary;
  }

  .simple-chart-stroke{
    stroke: $primary;
  }

  .simple-chart-title{
    margin-top: 0rem;
    margin-bottom: .5rem;
  }
  .simple-chart-description{
    // margin-top: 2px;
    // font-size: 1.2rem;
    // color: $gs3;
    text-align: right;
  }

  .simple-chart-no-data{
    font-size: 1.2rem;
    opacity: .5;
    
  }
}