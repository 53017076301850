@import '../../kit/styles/themes.scss';


.query-chart-wrapper{
  .query-chart-wrapper-title{
    font-family: $readableFontFamily;
    // font-size: 1.5rem;
    // font-weight: 600;
    // color: $gs0;
    margin-bottom: 1rem;
  }
  .query-chart-wrapper-inner{
    // min-height: 25rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    .recharts-cartesian-grid{
      shape-rendering: crispedges;
      line{
        stroke: $gs8 !important;
      }
    }

    .recharts-default-tooltip{
      padding: 1rem;
      background: $gs10 !important;
      border-radius: $commonRadius !important;
      border: 1px solid $gs0 !important;
      font-family: $readableFontFamily;
      font-size: 1.2rem;
      color: $gs0;
    }

    .recharts-responsive-container{
      flex-grow: 1;

      .recharts-tooltip-cursor{
        fill: $gs9;
        opacity: .5;
      }

      .recharts-text{
        font-family: $readableFontFamily;
        font-size: 1.1rem;
        fill: $gs0;
      }
      .recharts-bar-rectangle{
        fill: $primary;
      }
    }
  }
}