@import '../../kit/styles/themes.scss';


.component-content-thumbnail{
  // color: $gs0;
  // padding: .5rem;
  font-size: 1.4rem;
  overflow-x: hidden;

  .react-json-view{
    font-size: 1.2rem;
    line-height: 1.1;
  }

  font-weight: 400;
  // border: 1px solid $gs8;
  // border-radius: $commonRadius;

  &.component-content-thumbnail-success{
    border-color: $success;
  }

  &.component-content-thumbnail-danger{
    border-color: $danger;
  }

  &.component-content-thumbnail-warning{
    border-color: $warning;
  }

  &.component-content-thumbnail-primary{
    border-color: $primary !important;
  }


  .component-content-thumbnail-rendered-details{
    overflow-x: hidden;
  }

  .component-content-thumbnail-display-name{
    font-size: 2rem;
    font-weight: 900;
    // line-height: 1.5;
    margin-bottom: .2rem;
  }

  .component-content-show-details-switch{
    cursor: pointer;
    &:hover{
      color: $primary;
    }
  }

  .component-content-thumbnail-details{
    margin-top: 1rem;
  }

}

.component-content-link{
  display: block;
  margin-bottom: .5rem;
  font-weight: 400;
  &:hover{
    .component-content-thumbnail-display-name{
      color: $primary;
    }
  }
}



.component-content-swatch{
  width: 6rem;
  height: 6rem;
  position: relative;
  font-size: 1.2rem;
  color: $primary;
  background: $gs9;
  border-radius: $commonRadius;
  overflow: hidden;


  &.component-content-swatch-mini{
    width: 4rem;
    height: 4rem;
    font-size: .7rem;
    color: $gs4;
    background: $gs9;
  }

  >*{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
