@import '../../kit/styles/themes.scss';

.mega-markdown{
  
  > p{
    &:first-child{
      margin-top: 0px;
    }
    &:last-child{
      margin-bottom: 0px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: $commonRadius;
    border: 1px solid $gs95;
  }
}