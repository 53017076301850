@import '../../kit/styles/themes.scss';



.ascii-background{
  z-index: -100;
  overflow: hidden;
  font-family: $monospaceFontFamily;
  transition: background-color 1000ms ease-in-out;

  .ascii-background-row{
    z-index: -100;
    overflow-x: hidden;
    opacity: 1;
    // font-weight: 900;
    transition: color 1000ms ease-in-out;

  }
}