@import 'kit/styles/themes.scss';


.flow-interaction-layer-generator{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .flow-interaction-layer-generator-backdrop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1px);
    // opacity: .5;
  }

  .flow-interaction-layer-generator-inner-large{
    width: 500px;
    // height: 300px;
    position: relative;
    overflow: hidden;
    box-shadow: $boxShadowLarge;
    border: 1px solid $gs95;
  }

  .flow-interaction-layer-generator-inner{
    width: 400px;
    height: 150px;
    position: relative;
    overflow: hidden;
    box-shadow: $boxShadowLarge;
    border: 1px solid $gs95;
    // border: 2px solid $gs0;


    .flow-interaction-layer-generator-textarea{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: none;
      padding: 1.5rem;
      resize: none;
      font-family: $readableFontFamily;

      &:focus, &:active{
        outline: none;
      }
    }

    .flow-interaction-layer-generator-footer{
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      display: inline-block;
    }
  }
}

.flow-interaction-layer{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .flow-interaction-layer-generated-explanation{
    margin-top: 1rem;
    .mega-markdown{
      // font-size: smaller;
      p{
        font-size: 12.5px;
      }
    }
  }

  .flow-interaction-layer-error{
    padding: 1rem 1.5rem;
    padding-bottom: 1.2rem;
    background-color: lighten($danger, 45%);
    color: $danger;
    border-radius: $commonRadius;
    margin-bottom: .75rem;
    box-shadow: $boxShadowSmall;
  }

  .flow-interaction-layer-selected-node-box{
    position: absolute;
    z-index: 100;
    right: 1rem;
    top: 120px;
    bottom: 0rem;
    width: 400px;
    padding-bottom: 1rem;
    overflow-y: auto;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    .box{
      pointer-events: auto;
    }
  }
}

.flow-interaction-layer-try-it{
  overflow: hidden;
  pointer-events: auto;
  // border: 2px solid $gs0;

  .flow-interaction-layer-try-it-header{
    height: 2rem;
    min-height: 2rem;
    padding: 1rem 1.3rem;
    background-color: $gs10;
    color: $gs0;
    font-weight: 400;
    border-bottom: 1px solid $gs9;
    overflow: hidden;

    font-weight: 600;
    cursor: pointer;
    &:hover{
      background-color: $gs9;
    }
  }

  &.flow-interaction-layer-try-it__maximized{
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    // align-items: flex-start;

    // make the header no hover
    .flow-interaction-layer-try-it-header{
      cursor: default;
      &:hover{
        background-color: $gs10;
      }
    }

    .flow-interaction-layer-try-it-body{
      position: relative;
      flex-grow: 1;
    }

  }

  &.flow-interaction-layer-try-it__minimized{
    user-select: none;
    height: 2rem;
    min-height: 2rem;
    background-color: $gs10;
    color: $gs0;
    padding: 1rem 1.3rem;
    flex-grow: 0;
    cursor: pointer;
    font-weight: 600;
    &:hover{
      border: 1px solid $gs95;
      background-color: $gs9;
    }
  }
}

.flow-renderer-parent{
  position: relative;
  width: 100%;
  height: 100%;
  // background-color: $gs95;
}

.flow-renderer{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  font-family: $readableFontFamily;

  &:focus{
    outline: none;
    border: none;
  }

  .flow-renderer-bg-fill{
    // fill: $gs8;
    stroke: $danger;
    stroke-width: 10;
  }

  &.zoom-cursor{
    cursor: zoom-in;
  }
  &.pan-cursor{
    cursor: grab;
    &:active{
      cursor: grabbing;
    }
  }

  .flow-drag-bounds{
    stroke: $gs0;
    stroke-width: 1;
    fill: $gs7;
    opacity: .5;

    &.flow-drag-bounds-crossing{
      stroke-dasharray: 5,5;
    }
  }
}

.flow-renderer-minimap{
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 100px;
  // height: 100px;

  svg{
    box-shadow: $boxShadowLarge;
    background: $gs95;
    opacity: .75;
    border: 1px solid $gs9;
    border-radius: $commonRadius;
    overflow: hidden;
  }

  .flow-renderer-minimap-viewbox{
    stroke: $gs0;
    stroke-width: 10;
    fill: $gs10;
  }
}

.custom-cursor {
  transition: transform 0.1s linear;
  

  .cursor-text{
    font-family: $monospaceFontFamily;
    font-size: 12px;
  }

  img{
    border: 1px solid $gs0;
  }
}

.flow-snap-line{
  stroke: $gs0;
  stroke-width: 1;
  stroke-dasharray: 5, 5;
  opacity: .5;
}

.flow-group-rect{
  fill: $gs5;
  stroke: $gs0;
  stroke-width: 1;
  opacity: .25;
  rx: .5rem;
  ry: .5rem;
}

.flow-node{
  // transition: all .05s linear;
  user-select: none;

  .flow-node-request-bar{
    fill: $primary;
  }

  .flow-node-plugin-notch{
    fill: $primary;
    opacity: .75;
    stroke: none;
    pointer-events: none;
  }
  .flow-node-plugin-dropzone-container{
    opacity: 0;
    // transition: all .35s ease-in-out;

    .flow-node-plugin-dropzone-label{
      fill: $gs0;
      opacity: .5;
      font-size: 1.2rem;
      font-weight: 600;
      pointer-events: none;

      transition: all .2s ease-in;

    }
    
    .flow-node-plugin-dropzone-link{
      fill: $primary;
      opacity: .5;
      // stroke: $gs0;
      // stroke-width: 1;
      // stroke-dasharray: 5, 5;
      pointer-events: none;
      transition: all .35s ease-in;
    }

    .flow-node-plugin-dropzone{
      fill: rgba(255,255,255,0);
      opacity: .85;
      stroke: $gs0;
      stroke-width: 1;
      stroke-dasharray: 5, 5;
      pointer-events: none;
      transition: all .2s ease-in;
      
      &.flow-node-plugin-dropzone__highlight{
        fill: rgba(255,255,255,.5);
        opacity: 1;
        stroke-width: 2;
        stroke: $success;
      }
    }

    &.flow-node-plugin-dropzone-container__show{
      opacity: 1;

    }
  }

  .flow-node-show-additional{
    text-align: center;
    padding: 2px;
    color: $gs7;
    cursor: pointer;
    border-radius: 0px 0px .5rem .5rem;
    &:hover{
      background-color: $gs9;
      color: $gs0;
    }
  }

  .flow-node-missing-settings-error{
    color: $danger;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .flow-node-background{
    fill: $gs10;
    rx: .5rem;
    ry: .5rem;
    stroke: $gs0;
    stroke-width: 0;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, .15));
  }

  .flow-node-border{
    fill: rgba(0,0,0,0);
    rx: .5rem;
    ry: .5rem;
    stroke: $gs0;
    stroke-width: 0;
    transition: stroke 1s ease-in-out, fill 1s ease-in-out;
    pointer-events: none;

    &.flow-node-border__error{
      stroke: $danger;
    }
  }

  .flow-node-background{

    transition: stroke 1s ease-in-out, fill 1s ease-in-out;
  }

  &.flow-node__selected{
    .flow-node-border,
    .flow-node-request-bar{
      stroke-width: 4;
      stroke: $gs0;
    }
  }

  &.flow-node__highlight{
    .flow-node-border{
      stroke: $primary;
      transition: stroke 0s ease-in-out, fill 0s ease-in-out;
      // fill: transparentize($color: $primary, $amount: .5)

      fill: url(#flow-node-gradient);
    }
    .flow-node-background{
      transition: stroke 0s ease-in-out, fill 0s ease-in-out;
      fill: transparentize($color: $primary, $amount: .5)
    }
  }

  &.flow-node__plugin-connected{
    // disable drag on the label
    .flow-node-label{
      cursor: default;
      pointer-events: none;
    }
  }


  .flow-node-label{
    padding: 1rem;
    padding-right: 1rem;
    height: 36px;
    cursor: grab;
    fill: $gs0;
    font-size: 1.3rem;
    font-weight: 900;
    border-bottom: 1px solid $gs8;
    user-select: none;
    border-radius: .5rem .5rem 0 0;

    &.flow-node-label__no-interaction{
      cursor: default;
      pointer-events: none;
    }

    &:hover,&:active{
      background: $gs9;
    }

    &:active{
      cursor: grabbing;
    }

    &.flow-node-label__messages{
      background: lighten($component, 30%);
      color: $gs0 !important;
      .flow-node-label-text{
        color: $gs0 !important;
      }
      &:hover,&:active{
        background: lighten($component, 20%);
      }
    }

    &.flow-node-label__llm{
      background: $component;
      color: $gs10 !important;
      .flow-node-label-text{
        color: $gs10 !important;
      }
      &:hover,&:active{
        background: darken($component, 10%);
      }
    }

    &.flow-node-label__knowledge{
      background: $knowledge;
      color: $gs10 !important;
      .flow-node-label-text{
        color: $gs10 !important;
      }
      &:hover,&:active{
        background: darken($knowledge, 10%);
      }
    }

    &.flow-node-label__common{
      background: $gs95;
      &:hover,&:active{
        background: $gs8;
      }
    }

    .flow-node-header{
      
    }

    .flow-node-label-text{
      // text-transform: uppercase;
      
      color: $gs0;
      opacity: .9;
      font-size: 1.3rem;
      
      word-wrap: break-word;
      
      position: relative;
      top: -1px;
    }

    .flow-node-label-name{
      // font-size: 1rem;
      font-weight: 600;
      font-size: 1.4rem;
      
    }

    .flow-node-label-icon{
      margin-right: .8rem;
      font-size: 1.4rem;
    }
    .flow-node-label-button-icon{
      pointer-events: auto;
      cursor: pointer;
      // color: $gs0;
      opacity: .75;
      transition: opacity .1s ease-in-out;
      text-align: center;
      &:hover{
        opacity: 1;
      }
      border-radius: 4px;
      width: 15px;

      &.flow-node-label-button-icon__error{
        color: $danger;
      }
      &.flow-node-label-button-icon__disabled{
        opacity: .25;
        cursor: not-allowed !important;
        pointer-events: none;
        &:hover{
          opacity: .25;
        }
      }
    }
  }

  .flow-node-port-label{
    font-size: 12px;
    user-select: none;

    &.flow-node-port-label-required-error{
      fill: $danger;
    }
    &.flow-node-port-label__plugin-connected{
      fill: $primary;
    }
  }

  .flow-node-port{
    stroke: $gs0;
    stroke-width: 1;
    fill: $gs10;
    r: 5;

    transition: all .2s ease-in-out;

    &.flow-node-port__drafting{
      fill: $gs0 !important;
      r: 5 !important;
    } 

    &.flow-node-port__connected{
      fill: $gs0;
    }

    @keyframes pulse {
      0%, 100% {
        r: 5;
        // fill: $gs10;
      }
      25%,75% {
        r: 7;
        // fill: $gs0;
      }
    }

    &.flow-node-port__acceptable{
      animation: pulse 1s infinite;
    }

    &.flow-node-port__unacceptable{
      r: 0;
    }

    &:hover{
      fill: $gs0;
      r: 12;
      cursor: pointer
    }
  }


  .flow-node-custom-render{
    width: 100%;
    height: 100%;
    background: $gs2;
    color: $gs10;
    .flow-node-custom-render-container{
      // border-bottom: 1px solid rgba(0,0,0,.5);
    }
    &:hover{
      background: $gs2;
    }

  }
}

.flow-plugin-link{
  .flow-plugin-link__rect{
    fill: $primary;
    opacity: .25;
    stroke: none;
  }
}

.flow-link{
  cursor: pointer;
  

  path{
    stroke: $gs0;
    stroke-width: 2;
    fill: none;
    transition: opacity .1s ease-in-out;
  }

  .flow-link-selector-path{
    stroke: $gs0;
    opacity: .1;
    stroke-width: 10;
    fill: none;
  }

  &.flow-link__draft{
    pointer-events: none;
    
    path{
      stroke: $gs0;
      stroke-width: 2;
      // stroke-dasharray: 5, 5;
      opacity: .5;
    }
  }

  &.flow-link__autocomplete{
    opacity: .3;
    transition: d 1s ease-in-out;
  }

  &.flow-link__compatible{

  }

  &.flow-link__incompatible{
    path{
      stroke: $danger;
    }
  }
  &.flow-link__maybe{
    path{
      stroke: $warning;
    }
  }

  &.flow-link__selected{
    path{
      stroke-width: 10;
    }
  }
  &:hover{
    .flow-link-selector-path{
      opacity: .5;
    }
  }

  &.flow-link__maybe-null{
    path{
      stroke-dasharray: 10, 10;
    }
  }
  &.flow-link__cached{
    path{
      stroke: $success;
    }
  }

  .flow-link-button-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;

    .flow-link-button-inner{
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }


    .flow-link-button__button{
      padding: 1rem;
      background: $gs10;
      border-radius: $commonRadius;
      box-shadow: $boxShadowMedium;
      border: 1px solid $gs9;
      &:hover{
        background: $gs95;
      }
      &:active{
        background: $gs8;
      }
    }
  }
}