@import '../../kit/styles/themes.scss';


.better-chat{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  // border-radius: 0px $commonRadius $commonRadius 0px;

  .better-chat-greeting{
    padding: 3rem;
    position: absolute;
    z-index: 100;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .better-chat-messages{
    flex-grow: 1;
    position: relative;

    .better-chat-messages-inner{
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      overflow: auto;
      padding: 1rem;

      .better-chat-message{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 1.5rem;

        .better-chat-message-avatar{
          margin-right: 1rem;
        }

        .better-chat-message-body{
          padding-top: .5rem;
          flex-grow: 1;

          .better-chat-message-role-line{
            margin-bottom: 0.5rem;
          }

          .better-chat-message-role{
            font-size: 1.4rem;
            font-weight: bold;
          }

          .better-chat-message-content{
            font-size: 1.4rem;
            margin-bottom: 1rem;
          }

          .better-chat-message-timestamp{
            font-size: 1rem;
            color: $gs6;
          }

          .better-chat-message-actions{
            font-size: 1rem;
            color: $gs5;
            i{
              margin-right: 0.5rem; 
              cursor: pointer;
              &:hover{
                color: $gs2;
              }
              &:active{
                color: $gs0;
              }
            }
          }
        }
      }
    }
      
    .better-chat-warning{
      position: absolute;
      bottom: .5rem;
      right: .5rem;
      font-size: 1rem;
      color: $gs7;
      text-align: right;
      pointer-events: none;
    }
  }


  .better-chat-input{
    min-height: 6rem;
    height: 6rem;
    position: relative;
    border-top: 1px solid $gs9;
    
    &.better-chat-input__disabled{
      background: $gs95;
      color: $gs8;
      cursor: not-allowed;
    }

    textarea{
      position: absolute;
      left: 0px;
      right: 6rem;
      top: 0px;
      resize: none;
      border: none;
      height: 4rem;
      max-height: 4rem;
      min-height: 4rem;
      display: block;
      font-family: $readableFontFamily;
      padding: 1rem;
      background: none;
      border-radius: 0  0 0 $commonRadius;


      &:focus,&:active{
        outline: none;
      }
    }
    button{
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      bottom: 0.5rem;
      width: 5rem;
      font-size: 1.8rem;
      background: $gs0;
      color: $gs10;
      border: none;
      border-radius: $commonRadius;
      cursor: pointer;

      &.better-chat-send__disabled{
        background: $gs9;
        color: $gs7;
        cursor: not-allowed;
      }

      &:hover{
        background: $gs1;
      }
      &:active{
        background: $gs2;
      }
    }
  }
}