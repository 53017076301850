@import '../../styles/themes.scss';

.callout{
  position: relative;
  border-radius: $commonRadius;
  // border: 1px solid $gs9;
  overflow: hidden;
  padding: 2rem !important;
  background: $gs95;

  margin-bottom: 1rem;

  h4{
    font-size: 1.8rem;
    margin-top: 0;
    font-weight: 900;
  }

  .callout-title-icon{
    margin-right: 1rem;
  }

  .callout-basic-content{
    margin-top: 0;
    
    &:last-child{
      margin-bottom: 0;
    }
    font-size: 1.4rem;
  }


  &.callout-dismissable{
    // padding-top: 4rem;
  }

  .callout-exit-button{
    position: absolute;
    top: .5rem;
    right: .8rem;
    color: $gs5;
    cursor: pointer;

    &:hover{
      color: $gs0;
    }
  }

  &.callout-mini{
    padding: 1rem 1rem;
    .callout-basic-content{
      font-size: 1.3rem;
    }

    .callout-exit-button{
      top: 50%;
      margin-top: -1rem - .2rem;
      right: 1rem;
    }
  }


  &.callout-transparent{
    border-color: transparent;
    background: $gs9;
    color: $gs2;

  }

  &.callout-info{
    border-color: $info;
    background: lighten($info, 20%);
    h5{
      color: darken($info, 20%);
    }
    p{
      color: darken($info, 40%);
    }
  }


  &.callout-info{
    border-color: $info;
    background: lighten($info, 22%);
    h5{
      color: darken($info, 20%);
    }
    p{
      color: darken($info, 40%);
    }
  }


  &.callout-warning{
    border-color: $warning;
    background: lighten($warning, 34%);
    h5{
      color: darken($warning, 15%);
    }
    p{
      color: darken($warning, 40%);
    }
  }

  &.callout-danger{
    border-color: $danger;
    background: lighten($danger, 44%);
    h5{
      color: $danger;
    }
    p{
      color: darken($danger, 30%);
    }
  }

  &.callout-success{
    border-color: $success;
    background: lighten($success, 42%);
    h5{
      color: $success;
    }
    p{
      color: darken($success, 30%);
    }
  }

  &.callout-white{
    border-color: $gs1;
    border: 2px solid $gs1;
    background: $gs10;
    color: $gs0;
    h5{
      color: $gs0;
    }
    p{
      color: $gs0;
    }
  }

}
