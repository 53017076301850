@import '../../styles/themes.scss';

.dropdown{
  position: relative;

  .dropdown-toggle{
    cursor: pointer;
  }

  &.dropdown-disabled{
    .dropdown-toggle{
      cursor: auto;
    }
  }

  .dropdown-toggle-default{
    &:hover{
      color: $primary;
    }
  }

  .dropdown-menu{
    position: absolute;
    margin-top: .5rem;
    background: $gs10;
    z-index: 1001;
    padding: .5rem;
    min-width: 20rem;
    border: 2px solid $gs0;
    box-shadow: 0px 3px 6px rgba(0,0,0,.08);
    border-radius: $commonRadius;
    overflow: auto;



    &.dropdown-menu-align-right{
      right: 0;
    }

    &.dropdown-menu-position-right{
      top: -.5rem;
      left: 100%;
      border-radius: 0 $commonRadius $commonRadius 0;
    }


    .dropdown-menu-item-divider{
      margin-left: 0rem;
      margin-right: 0rem;
      height: 0rem;
      position: relative;
      padding: 0;
      margin-top: .6rem;
      margin-bottom: 1rem;

      &:before{
        position: absolute;
        content: '';
        bottom: 0rem;
        left: -.5rem;;
        right: -.5rem;
        height: 1px;
        background: $gs9;
        z-index: 1000;
      }
    }

    .dropdown-menu-item{
      display: block;
      cursor: pointer;
      border-radius: $commonRadius;
      position: relative;
      font-size: 1.4rem;

      &.dropdown-menu-item-disabled{
        pointer-events: none;
        a{
          color: $gs8 !important;
        }
      }

      // &:after{
      //   content: '';
      //   position: absolute;
      //   bottom: -.1rem;
      //   left: $commonRadius;
      //   right: $commonRadius;
      //   height: .1rem;
      //   background: $gs9;
      // }

      &:last-child{
        border-bottom: none;
        &:after{
          content: none;
        }
      }

      &.dropdown-menu-item-before-divider{
        border-bottom: none;
        &:after{
          content: none;
        }
      }

      &:hover{
        background: $gs9;
        overflow: hidden;
        &:after{
          content: none;
        }
      }

      > span{
        padding: .8rem 1rem;
        display: block;
        color: $gs2;
        font-weight: 400;

        &:hover{
          color: $gs0;
          .icon-muted-until-hover{
            color: $gs0;
          }
        }
      }


      a{
        padding: .8rem 1rem;
        display: block;
        text-decoration: none;
        font-weight: 400;
        color: $gs2;
        &:hover{
          color: $gs0;
        }

        &.dropdown-link-disabled{
          opacity: .5;
          pointer-events: none;
        }
      }
    }
  }
}
