@import '../../kit/styles/themes.scss';



.component-madlib{
  overflow: hidden;
  .component-madlib-inner{

    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;


    .component-madlib-content{
      flex-grow: 1;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      overflow: hidden;
      margin-top: .5rem;
    }


    .component-madlib-top-right{
      position: absolute;
      top: .7rem;
      right: .5rem;
      color: $gs5;
      font-size: 1.3rem;


    }


    .component-madlib-results-parent{
      // margin-top: 1.8rem;
    }

    .component-madlib-result{
      position: relative;
      overflow-y: auto;
      flex-grow: 1;
      // background: $gs95;
      // padding: 2rem;
      

      .component-madlib-pre{
        color: $gs0;
        background: none;
        padding: 0px;
        font-family: $readableFontFamily;
        p{
          &:first-child{
            margin-top: 0;
          }
        }
      }
    }
  }
}
