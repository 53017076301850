@import '../../kit/styles/themes.scss';

.histogramContainer {
  position: absolute;
  top: .5rem;
  left: .5rem;
  right: .5rem;
  bottom: .5rem;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.histogramBar {
  background-color: $knowledge;
  transition: height 0.3s ease;
  margin: 0px;
  // border-radius: 3px;

  // This &-tooltip class is hypothetical for adding additional styles
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}
