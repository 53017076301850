@import '../../kit/styles/themes.scss';

.carousel {
  height: 510px;
  overflow-x: hidden;
  // cursor: grab;

  // &:active {
  //   cursor: grabbing;
  // }
}

.carousel-track {
  display: flex;
  align-items: center;
  transition: transform 0.1s linear;
}

.carousel-item {
  flex-shrink: 0;
  border-radius: $commonRadius;
  overflow: hidden;
  user-select: none;
  
  box-shadow: 0px 0px 1px 0px rgba(8, 11, 14, 0.1), 0px 3px 6px -1px rgba(8, 11, 14, 0.2);
  border: 2px solid $gs0;

  &:hover{
    box-shadow: $boxShadowLarge;
    // border: 2px solid $gs0;
  }

  &.carousel-item-border{
    // border: 1px solid $gs0;
    // border-radius: $commonRadius;

  }
}
