@import '../../styles/themes.scss';


#modal-select-grabber{
  position: absolute;
  // top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}

.modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99998;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(255,255,255,.75);
  backdrop-filter: blur(5px);


  .modal-inner{
    position: relative;
    background: $gs10;
    border-radius: $commonRadius;
    // overflow: hidden;
    padding: 2.5rem;
    border: 2px solid $gs0;
    box-shadow: $boxShadowLarge;

    max-height: 70vh;
    overflow-y: auto;
    min-width: min(80vw, 40rem);
    max-width: min(40rem, 80vw);

    &.modal-inner-exitable{
      padding-top: 3rem;
    }

    &.modal-style-minimal{
      border: none;
      padding: 0px;
    }

    .modal-inner-exit-button{
      position: absolute;
      top: .6rem;
      right: 1rem;
      color: $gs7;
      cursor: pointer;

      &:hover{
        color: $gs0;
      }
    }

    .modal-inner-button-row{
      margin-top: 3rem;
      display: flex;
      justify-content: flex-end;
      >*{
        margin-left: 1rem;
      }
    }
  }
}
