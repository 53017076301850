@import '../../kit/styles/themes.scss';

.docs-manager{
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: $gs0;
  color: $gs10;
  justify-content: space-between;
  align-items: stretch;

  .docs-content-header{
    background: $gs0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    z-index: 100;

    .input{
      background: $gs10;
      border: 1px solid $gs8;
      color: $gs0;
    }
    .custom-field-description{
      color: $gs1;
    }
  }

  .docs-mobile-menu{
    background: $gs1;
    color: $gs10;
    padding: 1rem;
  }

  .docs-menu-wrapper{
    min-width: 25rem;
    overflow: auto;
    background: $gs10;
    color: $gs0;
    border-right: 1px solid $gs0;
    position: relative;

    .input{
      background: $gs10;
      border: 1px solid $gs8;
      color: $gs0;
    }
    .custom-field-description{
      color: $gs1;
    }
  }

  .docs-content-wrapper{
    flex-grow: 1;
    position: relative;
    overflow: auto;
    background: $gs10;
    color: $gs1;
    position: relative;

    .docs-content-inner{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      align-items: stretch;
      justify-content: flex-start; 

      .docs-content-inner-markdown{
        flex-grow: 1;
        // max-width: 100rem;
      }

      img{
        max-width: 100%;
      }

      h1{
        margin-top: 3rem;
        &:first-child{
          margin-top: 0;
          padding-top: 0;
        }
      }
      h2{
        margin-top: 3rem;
        &:first-child{
          margin-top: 0;
          padding-top: 0;
        }
      }
      h3{
        margin-top: 3rem;
      }
      p{
        &:first-child{
          margin-top: 0;
        }
      }
    }

  }

  .doc-link{
    cursor: pointer;
    display: block;
    padding: .8rem 1rem;
    text-decoration: none;
    border-radius: $commonRadius;
    &:hover{
      background: $gs9;
      color: $gs0;
    }

    &.doc-link-selected{
      background: $gs0;
      color: $gs10;
    }
  }

  .docs-content-inner-toc-wrapper{
    
    min-width: 25rem;
  }

  .docs-toc{
    
    // overflow: auto;
    padding: 2rem;

    
    // margin-left: 2rem;
    a{
      font-weight: 400;
      font-size: 1.3rem;
    }

    ul{
      margin-top: .5rem;
      li{
        margin-left: 1rem;
      }
    }
  }

  .doc-category{
    margin-bottom: 1rem;
  }

  .doc-child{
    padding-left: 3.2rem;
    font-weight: 400;
  }
  .doc-part{
    padding-left: 4rem;
    font-weight: 400;
  }

  h1{
    font-size: 3.25rem;
  }
  h2{
    font-size: 2.5rem;
    margin-top: 0;
    padding-top: 1.5rem;
  }
  h3{
    font-size: 1.75rem;
    margin-top: 0;
    padding-top: 1.5rem;
  }

  li{
    font-size: 1.5rem;
    margin-bottom: .5rem;
  }

  .layout-content{
    border-bottom: 1px solid $gs9;
  }

  a{
    text-decoration: none;
  }

  img{
    border-radius: $commonRadius;
  }

  pre{
    // padding: 0px;
    // border-left-color: $gs1;
    // // overflow: hidden;
    // position: relative;
    // // background: none;

    // .copy-code-button{
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   background: $gs1;

    //   .button-inner-display{
    //     color: $gs6 !important;

    //   }
    //   &:hover{
    //     .button-inner-display{
    //       color: $gs10 !important;
    //     }
    //   }

    //   .button{
    //     &:hover{
    //       background: transparent;
    //       border-color: transparent;
    //     }
    //     &:active{
    //       .button-inner-display{
    //         color: $gs8 !important;
    //       }
    //     }
    //   }


    //   cursor: pointer;
    //   z-index: 1;
    
    // }

    // > div {
    //   > div{
    //     background: $gs1 !important;
    //     margin-top: 0 !important;
    //     margin-bottom: 0 !important;
    //   }
    // }
  }

}
