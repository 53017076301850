@import '../../kit/styles/themes.scss';

.code-highlighter{
  .code-highlighter-parameters{
    margin-top: 1.5rem;

    .code-highlighter-parameter{
      font-family: $readableFontFamily;
      padding: 1.5rem;

      border-bottom: 1px solid $gs9;
      &:last-child{
        border-bottom: none;
      }
      
      .code-highlighter-parameter-name{
        font-family: $readableFontFamily;
        cursor: pointer;
        &:hover{
          color: $gs6;
        }
      }
      
      .code-highlighter-parameter-description{
        font-family: $readableFontFamily !important;
        color: $gs1;
        font-size: 1.3rem;
        margin-top: .5rem;
        margin-bottom: 0px;
        &:last-child{
          margin-bottom: 0rem;
        }
      }
    }
  }

  .code-highlighter-block{
    margin-bottom: 0;
    margin-top: 0;
  }

  .code-highlighter-container{
    position: relative;
    word-break: break-word;


    pre{
      background: $gs1 !important;
      border-radius: $commonRadius;
      font-size: 1.3rem;
      padding: 1.25rem !important;
      // border-left: 8px solid $gs4;
      margin-bottom: 0;
      // font-family: $monospaceFontFamily !important;
      word-break: break-word;
      white-space: inherit;
      code{
        white-space: break-spaces !important;
        word-break: break-word !important;
      }

    }


    .react-json-view{
      background: $gs1 !important;
      border-radius: $commonRadius;
      padding: 2rem;
      font-size: 1.3rem !important;
      border-left: 8px solid $gs5;
      // font-family: $monospaceFontFamily !important;
      // line-height: 1.3rem;

      // disable anti-aliasing

      .string-value{
        color: $success !important;
      }
      .boolean-value{
        color: $blue !important;
      }
    }

    .code-highlighter-copy-button{
      z-index: 100;
      position: absolute;
      top: 0;
      right: 0;
      width: 4rem;
      height: 4.2rem;
      background: none;
      color: $gs5;
      font-size: 1.4rem;
      border: none;
      cursor: pointer;

      &:hover{
        color: $gs10;
      }
      &:active{
        color: $gs8;
      }
    }

  }

}