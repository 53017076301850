@import '../../kit/styles/themes.scss';


.notification{

  .box{
    position: relative;
    padding: 3rem;
    transition: border-color 1s linear;
  }
  margin-bottom: 2rem;

  &.notification-unread{
    .box{
      border-color: $primary;
    }
  }


  .notification-timestamp{
    position: absolute;
    top: .75rem;
    right: .75rem;

    font-size: 1.2rem;
    // text-transform: uppercase;
    color: $gs5;
  }
}